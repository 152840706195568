.input-phone-number input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.input-phone-number-simple {
  display: flex;
  align-items: center;
}

/* .flag-container {
  position: relative;
}

.flag-container .PhoneInputCountryIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
} */
